









import gsap from 'gsap';
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
	components:{}
})
export default class Tooltip extends Vue {

    public upsideDown:boolean = false;
    public opened:boolean = false;
	
    private currentContent:string;
    private mouseMoveHandler:any;
    private currentTarget:HTMLElement;
    private lastMouseEvent:MouseEvent;

	public mounted():void {
		this.initialize();
	}

	public beforeDestroy():void {
		document.removeEventListener('mousemove', this.mouseMoveHandler);
	}

	@Watch("$store.state.tooltip")
	private tooltipStateChange():void {
		let data = this.$store.state.tooltip;
		if(data) {
			this.show(data);
		}else{
			this.hide();
		}
	}

	/**
	 * Opens the tooltip
	 * @param content
	 */
	public show(content:string):void {
		if(this.$store.state.mobile) return;
		if(this.currentContent == content && this.opened) return;
		this.opened = true;
		this.currentContent = content;
		(<HTMLElement>this.$refs.content).innerHTML = content;
		gsap.killTweensOf(this.$el);
		gsap.to(this.$el, {duration:.2, opacity:1});
		
		if(this.lastMouseEvent) {
			this.$nextTick().then(_=> {
				this.onMouseMove(this.lastMouseEvent, false);
			})
		}
	}

	/**
	 * Hides the tooltip
	 */
	public hide():boolean {
		if(!this.opened) return false;
		this.opened = false;
		gsap.killTweensOf(this.$el);
		gsap.to(this.$el, {duration:.2, opacity:0, onComplete:()=>this.onHideComplete()});
		return true;
	}

    /**
     * Initializes the class
     */
    private initialize():void {
		this.opened = false;

		gsap.set(this.$el, {opacity:0});
		this.mouseMoveHandler = (e:MouseEvent) => this.onMouseMove(e);
		document.addEventListener('mousemove', this.mouseMoveHandler);
	}

	/**
	 * Moves the tooltip
	 * @param e
	 */
	private onMouseMove(e:MouseEvent, checkTarget:boolean = true):void {
		this.lastMouseEvent = e;
		if(checkTarget) {
			let target:HTMLDivElement = <HTMLDivElement>e.target;
			while(target && target != document.body) {
				if(target.dataset && target.dataset.tooltip) break;
				target = <HTMLDivElement>target.parentNode;
			}
			//Target can be null if pressing mouse inside window and moving outside browser while keeping mouse pressed (at least on chrome)
			if(target && target != document.body) {
				this.$store.dispatch("openTooltip", target.dataset.tooltip);
			}else if(this.opened) {
				this.$store.dispatch("closeTooltip");
			}
		}

		if(!this.opened) return;

		let holder = <HTMLDivElement>this.$refs.holder;
		let px:number = e.clientX - 19;
		let py:number = (e.clientY - holder.clientHeight - 20);
		px = Math.max(0, Math.min(window.innerWidth - holder.clientWidth, px))
		py = Math.max(0, Math.min(window.innerHeight - holder.clientHeight, py))
		if(py < 50) {
			py = e.clientY + 30;
			this.upsideDown = true;
		}else{
			this.upsideDown = false;
		}
		holder.style.left = px+'px';
		holder.style.top = py+'px';

		//Deep check if current hover item is still on DOM
		//Vue can remove/recreate items anytime, in this case
		//"mouseout" event is not fired which blocks the tooltip
		if(this.currentTarget) {
			let t:any = this.currentTarget;
			while(t.parentNode && t.parentNode != document.body){
				t = t.parentNode;
			}
			if(!t || !t.parentNode) {
				this.currentTarget = null;
				this.$store.dispatch("closeTooltip");
			}
		}
	}

	/**
	 * Called when hidding completes
	 */
	private onHideComplete():void {
		this.opened = false;
	}

}
