












































import Button from "@/components/Button.vue";
import ManipulableImage from "@/components/ManipulableImage.vue";
import Hints from "@/less/components/Hints.vue";
import Utils from "@/utils/Utils";
import gsap from "gsap";
import { SplitText } from "gsap/all";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
	components:{
		Button,
		Hints,
		ManipulableImage,
	}
})
export default class Game extends Vue {

	@Prop()
	public level:string;

	public data:LevelData = null;
	public showHints:boolean = false;
	public allFound:boolean = false;
	public finalTimeLeft:number = 0;
	public foundCount:number = 0;

	private disposed:boolean = false;
	private timerDuration:number = 2 * 60 * 1000;
	// private timerDuration:number = 2 * 1000;
	public timeLeft:number = -1;
	private startTime:number = Date.now();

	public get finalTimeLeftFormated():string {
		return Utils.formatDuration(this.finalTimeLeft, true);
	}

	public getStyles(item:LevelDataItem):any {
		return {
			top: item.y + 'px',
			left: item.x + 'px',
			width: item.w + 'px',
			height: item.h + 'px',
		}
	}

	public get time():string {
		return Utils.formatDuration(this.timeLeft, true);
	}

	public get showResult():boolean {
		return this.allFound || this.timeLeft == 0;
	}

	public mounted():void {
		let lvl = parseInt(this.level) - 1;
		lvl = lvl > 0 && lvl < this.$store.state.levels.length? lvl : 0;
		this.data = this.$store.state.levels[ lvl ];
		this.data.items.forEach(v => v.f = false);
		this.renderFrame();
	}

	public beforeDestroy():void {
		this.disposed = true;
	}

	public renderFrame():void {
		if(this.disposed) return;
		requestAnimationFrame(_=> this.renderFrame());
		
		this.timeLeft = this.timerDuration - (Date.now() - this.startTime);
		if(this.timeLeft < 0) this.timeLeft = 0;
	}

	public onShowHints():void {
		let bt = (this.$refs.infoBt as Vue).$el;
		gsap.set(bt, {scaleX:1, scaleY:1, rotate:0, opacity:1});
		gsap.from(bt, {duration:1, scaleX:1.2, ease:"elastic.out(1.5,.25)"});
		gsap.from(bt, {duration:1, scaleY:1.2, ease:"elastic.out(1.5,.25)"});
		this.showHints = true;
	}

	public onItemFound(i:LevelDataItem):void {
		if(i.f === true) return;
		i.f = true;
		this.burstParticles(i);
		this.allFound = true;
		this.foundCount = 0;
		for (let i = 0; i < this.data.items.length; i++) {
			if(this.data.items[i].f !== true) this.allFound = false;
			else this.foundCount++;
		}
		let bt = (this.$refs.infoBt as Vue).$el;
		gsap.set(bt, {scaleX:1, scaleY:1, rotate:0, opacity:1});
		gsap.from(bt, {duration:1, scaleX:1.2, ease:"elastic.out(2.5,.25)"});
		gsap.from(bt, {duration:1, scaleY:1.2, ease:"elastic.out(2.5,.25)"});
		gsap.fromTo(bt, {filter:"brightness(2)"}, {duration:1, filter:"brightness(1)"});
	}

	@Watch("showResult")
	public async onShowResult():Promise<void> {
		if(!this.showResult) return;
		this.finalTimeLeft = this.timerDuration - this.timeLeft;
		this.startTime = 0;
		this.timeLeft = 0;
		await this.$nextTick();

		let mySplitText = new SplitText(this.$refs.resultLabel, { type: "chars,words" });
		gsap.from(this.$refs.result, {duration:.5, scale:0, opacity:0, ease:"back.out"});
		let delay = .25;
		for(let i = 0; i < mySplitText.chars.length; i++) {
			const c = mySplitText.chars[i];
			// gsap.from(c, {duration:2, delay:i*.05, scale:0, rotate:Math.random()*180-90, ease:"elastic.out(2,.5)"});
			let rotate = Math.random()*10 + 30;
			if(Math.random() > .5) rotate *= -1;
			delay += .05
			gsap.from(c, {duration:1, scale:.8, rotate, opacity:0, delay, y:50, ease:"elastic.out"});
		}
		let bt = (this.$refs.backBt as Vue).$el;
		gsap.from(bt, {duration:1.5, delay, scaleX:.5, ease:"elastic.out(1.5,.25)"});
		gsap.from(bt, {duration:1.5, delay:delay+.1, scaleY:.5, ease:"elastic.out(1.5,.25)"});
		gsap.from(bt, {duration:.5, delay:delay+.1, y:0, opacity:0, rotate:35, ease:"back.out"});
		setTimeout(_=> {
			mySplitText.revert();
		}, (delay+1)*1000);
	}

	public onBack():void {
		this.$router.push("/");
	}

	public burstParticles(item:LevelDataItem):void {
		let stars = <Element[]>this.$refs.star;
		for (let i = 0; i < stars.length; i++) {
			const s = stars[i];
			gsap.killTweensOf(s);
			let px = item.x + item.w*.8 * Math.random() + item.w*.2;
			let py = item.y + item.h*.8 * Math.random() + item.h*.2;
			let angle = Math.atan2(py - (item.y+item.h/2), px - (item.x+item.w/2));
			let dist = Math.random() * 200 + 100;
			gsap.set(s, {opacity:1, left:px, top:py, scale:1});
			gsap.to(s, {opacity:0,
						rotation:(Math.random()-Math.random()) * Math.PI * 2.5+"rad",
						// x:"-50%",
						// y:"-50%",
						left:px + Math.cos(angle) * dist,
						top:py + Math.sin(angle) * dist,
						scale:0,
						duration:1.25});
		}
		setTimeout(_=> {
			//Reset stars to avoid page overflow on small screens
			for (let i = 0; i < stars.length; i++) {
				const s = stars[i];
				// gsap.set(s, {opacity:1, x:0, y:0, scale:0});
			}
		},1500)
	}

}

export interface LevelData {
	items:LevelDataItem[];
	picture:string;
}

export interface LevelDataItem {
	i:number;//Item type
	f:boolean;//Found?
	x:number;
	y:number;
	w:number;
	h:number;
}

