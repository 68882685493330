




























import gsap from 'gsap';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CirclesRendererCanvas from "./CirclesRendererCanvas.vue";

@Component({
	components:{
		CirclesRendererCanvas
	}
})
export default class Button extends Vue {

	@Prop({default: null})
	public icon!:string;
	@Prop({default: null})
	public iconSelected!:string;
	@Prop({default: null})
	public title!:string;
	@Prop({default: null})
	public name!:string;
	@Prop({default: false})
	public loading!:boolean;
	@Prop({default: "button"})
	public type!:string;
	@Prop({default: null})
	public target!:string;
	@Prop({default: null})
	public to!:any;
	@Prop({default: -1})
	public percent!:number;
	@Prop({default:false})
	public white!:boolean;
	@Prop({default:false})
	public big!:boolean;
	@Prop({default:false})
	public highlight!:boolean;
	@Prop({default:false})
	public selected!:boolean;
	@Prop({default:false})
	public disabled!:boolean;
	@Prop({default:false})
	public value!:boolean;
	@Prop({default:"image/*"})
	public accept!:string;

	public pInterpolated:number = -1;
	public checked:boolean = false;

	public get isIconSVG():boolean {
		return this.parsedIcon.indexOf("<") != -1;
	}

	public get checkMarkIcon():string {
		if(this.white !== false) {
			return require('@/assets/icons/checkmark_white.svg');
		}else{
			return require('@/assets/icons/checkmark.svg');
		}
	}

	public get nodeType():string {
		if(this.to) return "router-link";
		if(this.type == "checkbox") return "div";
		if(this.type == "link") return "a";
		return "button";
	}

	public get parsedIcon():string {
		if(this.selected !== false && this.iconSelected) {
			return this.iconSelected;
		}else{
			return this.icon;
		}
	}

	public get progressStyle():any {
		if(this.pInterpolated> -1 && this.pInterpolated<100) {
			let p:number = Math.round(this.pInterpolated);
			let color = "255, 255, 255";
			let alpha = .5;
			if(this.white !== false) {
				color = "75, 201, 194"
				alpha = .3;
			}
			return {backgroundImage: "linear-gradient(to right, rgba("+color+",0) "+p+"%,rgba("+color+",0) "+p+"%,rgba("+color+","+alpha+") "+p+"%,rgba("+color+","+alpha+") 100%)"};
		}else{
			return {};
		}
	}

	public get classes():any {
		let list =  ["button"]
		if(!this.title) list.push("noTitle");
		if(this.white !== false) list.push("white");
		if(this.big !== false) list.push("big");
		if(this.highlight !== false) list.push("highlight");
		if(this.selected !== false) list.push("selected");
		if(this.loading !== false) list.push("disabled");
		if(this.disabled !== false) list.push("disabled");
		if(this.type == "checkbox") list.push("checkbox");
		return list;
	}

	public mounted():void {
		this.checked = this.value;
	}
	
	public beforeDestroy():void {
		
	}

	public resetBrowse():void {
		(<HTMLFormElement>this.$refs.browse).value = null;
	}

	public onClick(event):void {
		if(this.disabled) return;
		this.$emit('click', event);//bubble up event to avoid having to listen for @click.native everytime
	}

	@Watch("checked")
	public onSelectStateChange():void {
		this.$emit('input', this.checked);
	}

	@Watch("value")
	public onCalueChange():void {
		if(this.type=='checkbox') {
			this.checked = this.value;
		}
	}

	@Watch("percent")
	private onPercentChange():void {
		let duration = this.percent < this.pInterpolated? 0 : .35;
		gsap.killTweensOf(this);
		gsap.to(this, {duration, pInterpolated:this.percent, ease:"sine.inout"});
	}

}
