import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		initialized: false,
		tooltip: null,
		levels: [
			{
				picture: "level1.jpg",
				items:[
					{f:false, x:889, y:784, w:82, h:123, i:1},
					{f:false, x:3573, y:910, w:719, h:272, i:2},
					{f:false, x:3990, y:2626, w:66, h:102, i:3},
					{f:false, x:1696, y:1979, w:163, h:255, i:4},
					{f:false, x:2337, y:2058, w:184, h:255, i:5},
					{f:false, x:3822, y:160, w:255, h:334, i:6},
					{f:false, x:2095, y:1227, w:151, h:293, i:7},
					{f:false, x:2932, y:1094, w:340, h:433, i:8},
					{f:false, x:150, y:2314, w:332, h:534, i:9},
					{f:false, x:3745, y:1269, w:96, h:203, i:10},
					{f:false, x:2473, y:295, w:271, h:309, i:11},
					{f:false, x:3190, y:2549, w:298, h:299, i:12},
				]
			},
		]
	},
	mutations: {
		openTooltip(state, payload) { state.tooltip = payload; },
		
		closeTooltip(state) { state.tooltip = null; },
	},
	actions: {
		async startApp({ state, commit, dispatch }, payload) { 
			
			// let res = await PubSub.instance.getSubsList("29961813");
			// console.log(res);
			this.state.initialized = true;
			return true;
		},

		openTooltip({commit}, payload) { commit("openTooltip", payload); },
		
		closeTooltip({commit}) { commit("closeTooltip", null); },
	},
})
